/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const excluirLeilao = async (LeilaoId) => {
    try {
        const res = await axios({
            method: 'DELETE',
            url: `/api/v1/leiloes/${LeilaoId}`,
        });

        if (res.status == 204) {
            showAlert('success', 'Leilão excluído com sucesso!');
            window.setTimeout(() => {
                location.assign('/gerenciar/leiloes');
            }, 500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const updateLeilao = async (
    id,
    obra,
    data_inicio,
    data_fim,
    lance_inicial,
    status,
) => {
    try {
        let method, url;

        if (id) {
            method = 'PATCH';
            url = `/api/v1/leiloes/${id}`;
        } else {
            method = 'POST';
            url = '/api/v1/leiloes';
        }

        const res = await axios({
            method,
            url,
            data: {
                obra,
                data_inicio,
                data_fim,
                lance_inicial,
                status,
            },
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Informações cadastradas com sucesso!');
            if (!id) location.reload(true);
            else
                window.setTimeout(() => {
                    location.reload(true);
                }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response);
    }
};

export const ativarLeilao = async (id) => {
    try {
        const data = {
            status: 'ativo',
        };

        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/leiloes/${id}`,
            data,
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Leilão ativado com sucesso!');
            window.setTimeout(() => {
                location.reload(true);
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const encerrarLeilao = async (id) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/leiloes/encerrarLeilao`,
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Leilão encerrado com sucesso!');
            window.setTimeout(() => {
                location.reload(true);
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const suspenderLeilao = async (id) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/leiloes/suspenderLeilao`,
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Leilão suspenso com sucesso!');
            window.setTimeout(() => {
                location.reload(true);
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
