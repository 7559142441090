/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const excluirObra = async (ObraId) => {
    try {
        const res = await axios({
            method: 'DELETE',
            url: `/api/v1/obras/${ObraId}`,
        });

        if (res.status == 204) {
            showAlert('success', 'Obra excluída com sucesso!');
            window.setTimeout(() => {
                location.assign('/gerenciar/obras');
            }, 500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const updateObra = async (data, novo) => {
    try {
        let method, url;
        if (!novo) {
            method = 'PATCH';
            url = `/api/v1/obras/${data.get('id')}`;
        } else {
            method = 'POST';
            url = '/api/v1/obras';
        }

        const res = await axios({
            method,
            url,
            data,
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Informações cadastradas com sucesso!');
            window.setTimeout(() => {
                location.assign('/gerenciar/obras');
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
