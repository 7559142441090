/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const excluirLance = async (LanceId) => {
    try {
        const res = await axios({
            method: 'DELETE',
            url: `/api/v1/lances/${LanceId}`,
        });

        if (res.status == 204) {
            showAlert('success', 'Lance excluído com sucesso!');
            window.setTimeout(() => {
                location.reload(true);
            }, 500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
