/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const excluirGrupo = async (GrupoId) => {
    try {
        const res = await axios({
            method: 'DELETE',
            url: `/api/v1/grupos/${GrupoId}`,
        });

        if (res.status == 204) {
            showAlert('success', 'Grupo excluído com sucesso!');
            window.setTimeout(() => {
                location.assign('/gerenciar/grupos');
            }, 500);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const updateGrupo = async (id, grupo, turma, saldo, chave) => {
    try {
        let method, url;
        if (id) {
            method = 'PATCH';
            url = `/api/v1/grupos/${id}`;
        } else {
            method = 'POST';
            url = '/api/v1/grupos';
        }

        const res = await axios({
            method,
            url,
            data: {
                grupo,
                turma,
                saldo,
                chave,
            },
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Informações cadastradas com sucesso!');
            window.setTimeout(() => {
                location.assign('/gerenciar/grupos');
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
