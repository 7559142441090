/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const updateSettings = async (data, type) => {
    try {
        const url =
            type === 'password'
                ? '/api/v1/usuarios/updateMyPassword'
                : '/api/v1/usuarios/updateMe';

        const res = await axios({
            method: 'PATCH',
            url,
            data,
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Informações atualizadas com sucesso!');
            window.setTimeout(() => {
                location.assign('/me');
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};

export const deleteMe = async () => {
    try {
        const res = await axios({
            method: 'DELETE',
            url: '/api/v1/usuarios/deleteMe',
        });
        if ((res.data.status = 'success')) location.assign('/');
    } catch (err) {
        console.log(err.response);
        showAlert(
            'error',
            'Erro ao desativar a conta! Tente novamente mais tarde.',
        );
    }
};
