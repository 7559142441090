/* eslint-disable */
import { login, logout } from './login';
import {
    encerrarLeilao,
    suspenderLeilao,
    ativarLeilao,
    updateLeilao,
    excluirLeilao,
} from './leilao';
import { updateObra, excluirObra } from './obra';
import { updateUsuario, excluirUsuario } from './usuario';
import { updateGrupo, excluirGrupo, creditarAtividade } from './grupo';
import { excluirLance } from './lance';
import { signup } from './signup';
import { updateSettings, deleteMe } from './updateSettings';

//const loginForm = document.querySelector('.form');
const loginForm = document.querySelector('.form--login');
const logOutBtn = document.querySelector('.nav__el--logout');
const signUpForm = document.querySelector('.form--signup');
const updateMeForm = document.querySelector('.form--updateme');
const updateMyPasswordForm = document.querySelector('.form--updatemypassword');
const deleteMeBtn = document.querySelector('.btn--delete-account');
const productModal = document.querySelector('.product-modal');
const ecerrarLeilaoBtn = document.getElementById('btnEncerrarLeilao');
const suspenderLeilaoBtn = document.getElementById('btnSuspenderLeilao');
const ativarLeilaoBtn = document.getElementById('btnAtivarLeilao');
const confirmDeleteMe = document.querySelector('.btn--confirmDeleteMe');
const leilaoForm = document.getElementById('form--leilao');
const obraForm = document.getElementById('form--obra');
const usuarioForm = document.getElementById('form--usuario');
const deleteLeilaoBtn = document.querySelector('.btn--delete-leilao');
const deleteObraBtn = document.querySelector('.btn--delete-obra');
const deleteUsuarioBtn = document.querySelector('.btn--delete-usuario');
const grupoForm = document.getElementById('form--grupo');
const deleteGrupoBtn = document.querySelector('.btn--delete-grupo');
const crediarAtividadeForm = document.getElementById(
    'form--creditar-atividade',
);
const lancesTable = document.getElementById('lancesTableBody');

if (lancesTable) {
    const { io } = require('socket.io-client');
    document.addEventListener('DOMContentLoaded', function () {
        // Certifique-se de que a conexão do socket.io está correta
        const socket = io();

        const lanceAtualDiv = document.getElementById('lanceAtual');
        const lancesTableBody = document.getElementById('lancesTableBody');

        // Ouvir o evento de novo lance do servidor
        socket.on('novoLance', (lance) => {
            const leilaoId = lance.leilao;
            const lanceId = lance._id;

            // Atualizar o lance atual
            lanceAtualDiv.innerHTML = `
                <h2 class='text-center'>${lance.valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })}</h2>            
                <h4 class='text-center'>Grupo: ${lance.grupo.turma} - ${
                    lance.grupo.grupo
                }</h4>
            `;

            lanceAtualDiv.classList.add('highlight'); // Adiciona a animação de piscar

            setTimeout(function () {
                lanceAtualDiv.classList.remove('highlight');
            }, 5000); // Remove animação após 5s

            // Adicionar o novo lance na tabela
            const novaLinha = document.createElement('tr');
            novaLinha.innerHTML = `
                <td class="align-middle">${new Date(
                    lance.data_hora,
                ).toLocaleString('pt-BR', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                })}</td>
                <td class="align-middle">${lance.grupo.turma} - ${
                    lance.grupo.grupo
                }</td>
                <td class="align-middle">${lance.valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                })}</td>
                <td class="action align-middle" data-title="Action">
                    <div>
                        <a class="delete lanceDeleteBtn" data-toggle="tooltip" data-placement="top" title="" data-leilao="${leilaoId}" data-lance="${lanceId}" data-original-title="Excluir Lance">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                </td>
            `;

            const elementsArray = novaLinha.querySelectorAll('.lanceDeleteBtn');

            if (elementsArray.length > 0) {
                elementsArray.forEach(function (elem) {
                    elem.addEventListener('click', (e) => {
                        const { leilao, lance } = e.currentTarget.dataset;
                        excluirLance(lance);
                    });
                });
            }

            lancesTableBody.prepend(novaLinha); // Adiciona o novo lance no topo da tabela
        });
    });
}

if (deleteGrupoBtn)
    deleteGrupoBtn.addEventListener('click', (e) => {
        e.preventDefault();

        const { grupo } = e.target.dataset;

        excluirGrupo(grupo);
    });

if (grupoForm) {
    grupoForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const grupo = document.getElementById('grupo').value;
        const turma = document.getElementById('turma').value;
        const saldo = document.getElementById('saldo').value;
        const chave = document.getElementById('chave').value;

        const campoId = document.getElementById('id');

        updateGrupo(campoId ? campoId.value : null, grupo, turma, saldo, chave);
    });
}

if (deleteUsuarioBtn)
    deleteUsuarioBtn.addEventListener('click', (e) => {
        e.preventDefault();

        const { usuario } = e.target.dataset;

        excluirUsuario(usuario);
    });

if (usuarioForm) {
    usuarioForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('nome', document.getElementById('nome').value);
        form.append('email', document.getElementById('email').value);
        form.append('papel', document.getElementById('papel').value);
        form.append('active', document.getElementById('active').checked);
        form.append('foto', document.getElementById('foto').files[0]);
        console.log(document.getElementById('active').checked);
        const campoId = document.getElementById('id');
        if (campoId) {
            form.append('id', campoId.value);
            updateUsuario(form, false);
        } else {
            form.append('password', document.getElementById('password').value);
            form.append(
                'passwordConfirm',
                document.getElementById('passwordConfirm').value,
            );
            updateUsuario(form, true);
        }
    });
}

if (deleteLeilaoBtn)
    deleteLeilaoBtn.addEventListener('click', (e) => {
        e.preventDefault();

        // const button = $(e.relatedTarget);
        // const leilao = button.data('leilao');
        const { leilao } = e.target.dataset;

        excluirLeilao(leilao);
    });

if (deleteObraBtn)
    deleteObraBtn.addEventListener('click', (e) => {
        e.preventDefault();

        const { obra } = e.target.dataset;

        excluirObra(obra);
    });

if (leilaoForm)
    leilaoForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const obra = document.getElementById('obra').value;
        const data_inicio = document.getElementById('data_inicio').value;
        const data_fim = document.getElementById('data_fim').value;
        const lance_inicial = document.getElementById('lance_inicial').value;
        const status = document.getElementById('status').value;

        const campoId = document.getElementById('id');

        updateLeilao(
            campoId ? campoId.value : null,
            obra,
            data_inicio,
            data_fim,
            lance_inicial,
            status,
        );
    });

if (obraForm)
    obraForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('obra', document.getElementById('obra').value);
        form.append('tipo', document.getElementById('tipo').value);
        form.append('ano', document.getElementById('ano').value);
        form.append('artista', document.getElementById('artista').value);
        form.append('descricao', document.getElementById('descricao').value);
        form.append('imagem', document.getElementById('imagem').files[0]);

        const campoId = document.getElementById('id');
        if (campoId) {
            form.append('id', campoId.value);
            updateObra(form, false);
        } else updateObra(form, true);
    });

const elementsArray = document.querySelectorAll('.lanceDeleteBtn');

if (elementsArray.length > 0) {
    elementsArray.forEach(function (elem) {
        elem.addEventListener('click', (e) => {
            const { leilao, lance } = e.currentTarget.dataset;
            excluirLance(lance);
        });
    });
}

if (confirmDeleteMe) {
    confirmDeleteMe.addEventListener('click', function (event) {
        const confirmDeleteForm = document.getElementById('confirmDeleteForm');
        if (confirmDeleteForm.confirmation.value === 'APAGAR') deleteMe();
    });
}

if (ecerrarLeilaoBtn)
    ecerrarLeilaoBtn.addEventListener('click', (e) => {
        const { leilao } = e.target.dataset;
        encerrarLeilao(leilao);
    });

if (suspenderLeilaoBtn)
    suspenderLeilaoBtn.addEventListener('click', (e) => {
        const { leilao } = e.target.dataset;
        suspenderLeilao(leilao);
    });

if (ativarLeilaoBtn)
    ativarLeilaoBtn.addEventListener('click', (e) => {
        const { leilao } = e.target.dataset;
        ativarLeilao(leilao);
    });

if (productModal) {
    $('#product-modal').on('show.bs.modal', function (e) {
        const button = $(e.relatedTarget);
        const obra = button.data('obra');
        const tipo = button.data('tipo');
        const ano = button.data('ano');
        const artista = button.data('artista');
        const descricao = button.data('descricao');
        const imagem = button.data('imagem');
        const slug = button.data('slug');

        const modal = $(this);
        modal.find('.product-title').text(obra);
        modal.find('.obra-dados--artista').text(`${artista}`);
        modal.find('.obra-dados--ano').text(`Ano: ${ano}`);
        modal.find('.obra-dados--tipo').text(`Categoria: ${tipo}`);
        modal.find('.product-short-description').text(descricao);
        document.getElementById('img-responsive').src =
            `/images/obras/${imagem}`;
        document.getElementById('obra-dados--link').href = `/obras/${slug}`;
    });
}

if (loginForm)
    loginForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('senha').value;
        login(email, password);
    });

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (deleteMeBtn) deleteMeBtn.addEventListener('click', deleteMe);

if (signUpForm)
    signUpForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const nome = document.getElementById('nome').value;
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const passwordConfirm =
            document.getElementById('passwordConfirm').value;
        signup(nome, email, password, passwordConfirm);
    });

if (updateMeForm)
    updateMeForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('nome', document.getElementById('nome').value);
        form.append('email', document.getElementById('email').value);
        form.append('foto', document.getElementById('foto').files[0]);

        updateSettings(form, 'data');
    });

if (updateMyPasswordForm)
    updateMyPasswordForm.addEventListener('submit', async (e) => {
        e.preventDefault();

        document.querySelector('.btn--save-password').textContent =
            'Atualizando...';
        document.querySelector('.btn--save-password').disable = true;

        const passwordCurrent =
            document.getElementById('passwordCurrent').value;
        const password = document.getElementById('password').value;
        const passwordConfirm =
            document.getElementById('passwordConfirm').value;
        await updateSettings(
            { passwordCurrent, password, passwordConfirm },
            'password',
        );

        document.querySelector('.btn--save-password').textContent =
            'Mudar Senha';
        document.querySelector('.btn--save-password').disable = false;

        document.getElementById('passwordCurrent').value = '';
        document.getElementById('password').value = '';
        document.getElementById('passwordConfirm').value = '';
    });

if (crediarAtividadeForm) {
    crediarAtividadeForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const grupo = document.getElementById('grupo').value;
        const turma = document.getElementById('turma').value;
        var valor = Number(document.getElementById('valor').value);

        if (e.submitter.id === 'debitar') {
            valor = -valor;
        }

        creditarAtividade(grupo, turma, valor);
    });
}
