/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const signup = async (nome, email, password, passwordConfirm) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/usuarios/signup',
            data: {
                nome,
                email,
                password,
                passwordConfirm,
            },
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Usuário inscrito com sucesso!');
            window.setTimeout(() => {
                location.assign('/me');
            }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
};
